<template>
  <div class="w-10/12 lg:w-9/12 text-left font-proximaMedium">
    <h2 class="text-2xl">{{ $t("phone_otp_by_sms_title") }}</h2>
    <h5 class="text-sm my-4">
      {{ $t("phone_otp_by_sms_info_text") }}<br /><span
        class="text-blue"
        v-if="phone_number"
        >{{ phone_number }}</span
      >
    </h5>
    

    <div>
      <FormError :errors="errorPin" />
      <FormInfo :infos="this.message" />
      <InputPin @blurred="inputPinVal" />
    </div>
    <Loader class="mt-6 flex justify-center" v-if="showLoader" />
    <SubmitButton
      :title="$t('global_verify')"
      background="bg-primary"
      @click.native="submitForm"
      v-else
    />
  </div>
</template>

<script>
import SubmitButton from "../buttons/SubmitButton.vue";
import InputPin from "../forms/InputPin.vue";
import Loader from "../loader/Loader.vue";
import FormError from "../warning/FormError.vue";
import FormInfo from "../warning/FormInfo.vue";

import { verifyOtp } from "../../services/smsService";

export default {
  props: {
    email_address: String,
    phone_number: String,
    provider: String,
    servicePracticeType: Number,
    message: String,
  },
  components: {
    SubmitButton,
    InputPin,
    Loader,
    FormError,
    FormInfo,
  },
  data() {
    return {
      pinNumber: "",
      errorPin: [],
      pinReq: true,
      showLoader: false,
    };
  },

  methods: {
    async submitForm() {
      this.errorPin = [];

      let role = localStorage.getItem("role");

      if (!this.pinNumber) {
        this.errorPin.push("Pin number required");
        this.pinReq = false;
      } else {
        this.pinReq = true;
        //this.showLoader = true;

        try {
          const { data } = await verifyOtp(
            this.email_address,
            this.phone_number,
            this.pinNumber
          );
          //Perform Success Action
          this.pinReq = true;
          //this.showLoader = true;
          //petOwner
          if (role == 1) {
            this.$router.push({
              name: "CompleteYourProfile",
              params: {
                email_address: this.email_address,
                phone_number: this.phone_number,
                provider: this.provider,
              },
            });
          }
          //shelter
          if (role == 6) {
            this.$router.push({
              name: "ShelterCompleteProfile",
              params: {
                email_address: this.email_address,
                phone_number: this.phone_number,
                provider: this.provider,
              },
            });
          }
          //dogbreed
          if (role == 3) {
            this.$router.push({
              name: "DogBreedCompleteProfile",
              params: {
                email_address: this.email_address,
                phone_number: this.phone_number,
                provider: this.provider,
              },
            });
          }
          if (role == 4) {
            this.$router.push({
              name: "ServiceproviderCompleteProfile",
              params: {
                email_address: this.email_address,
                phone_number: this.phone_number,
                provider: this.provider,
                serviceType: this.servicePracticeType,
              },
            });
          }
          if (role == 5) {
            this.$router.push({
              name: "VeterinarianCompleteProfile",
              params: {
                email_address: this.email_address,
                phone_number: this.phone_number,
                provider: this.provider,
                practiceType: this.servicePracticeType,
              },
            });
          }
        } catch {
          this.errorPin.push(
            "Incorrect PIN code you still have three attempts left"
          );
        }
      }
    },
    inputPinVal(val) {
      this.pinNumber = val;
    },
  },
};
</script>

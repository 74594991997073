<template>
  <div class="my-6 md:my-0 flex flex-col md:flex-row items-start">
    <SectionLeft />
    <div class="h-full md:h-screen w-full md:w-6/12 px-4 md:pr-16 lg:pr-32">
      <OtpVerificationSection
        :email_address="this.$route.params.email_address"
        :phone_number="this.$route.params.phone_number"
        :provider="this.$route.params.provider"
        :servicePracticeType="this.$route.params.servicePracticeType"
        :message="this.$route.params.message"
      
      />
    </div>
    <DataserviceLogo />
  </div>
</template>

<script>
import SectionLeft from "../components/SectionLeft.vue";
import OtpVerificationSection from "../components/otpverificationSocial/OtpVerificationSection.vue";
import DataserviceLogo from "../components/DataserviceLogo.vue"

export default {
  components: {
    SectionLeft,
    OtpVerificationSection,
    DataserviceLogo
  },
  data() {
    return {};
  },
};
</script>
